.banner__background {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
  height: 67vh;
  background-color: rgb(37, 37, 37);
}

.banner__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 67vh;
  background-image: url("./images/buildings.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
  opacity: 0.7;
  filter: blur(1px);
  overflow: hidden;
}

.banner__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 55vh;
  margin: 0 auto;
}

.banner__logo {
  width: 180px;
}

.banner__headline {
  color: rgb(233, 233, 233);
  text-shadow: 0 2px 5px #000;
}

@media (max-width: 1024px) {
  .banner__image {
  background-image: url("./images/buildings-m.jpeg");
  }
}