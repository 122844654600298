.contact {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  align-items: center;
}

.contact__subheadline {
  margin-bottom: 30px;
}

.contact__group {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  width: 100%;
}

.contact__checkbox {
  align-self: center;
}