.page {
  max-width: 1000px;
  width: 95%;
  margin: 70px auto;
  padding-bottom: 100px;
}

.page__headline {
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 2.5em;
}

.home__headline {
  padding-top: 50px;
}

.page__content {
  font-size: 1.15em;
  margin-bottom: 30px;
  line-height: 1.5em;

  .hours {
    color: #1C77B8;
    font-weight: bold;
  }

  .inside {
    color: #6B1210;
    font-weight: bold;
  }

  .date {
    letter-spacing: 1px;
    color: #003F6B;
    font-weight: bold;
  }

  .table {
    display: flex;
    width: 60%;
    justify-content: space-between;
    
    @media (max-width: 850px) {
      width: 80%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }

    @media (max-width: 400px) {
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
}

.page__underline {
  font-weight: bold;
  line-height: 1.5em;
  text-decoration: underline;
  letter-spacing: 1px;
}

.page__highlighted {
  color: #b80b09;
  font-weight: bold;
}

.page__underlined {
  text-decoration: underline;
  color: #b80b09;
  font-weight: bold;
}

.page__centered {
  text-align: center;
  font-size: 1.2em;
  line-height: 1.5em;
  margin-top: 10px;
  margin-bottom: 40px;
  letter-spacing: 1.5px;
}

.page__content--main {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.5em;
}

.page__content--styled {
  font-style: italic;
  margin-bottom: 30px;
  line-height: 1.5em;
}

.page__content--styledCentered {
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.5em;
}

.page__link {
  text-decoration: none;
  color: #b80b09;
  font-weight: bold;
  transition: 0.2s ease-out;

  &:hover {
    text-decoration: underline;
    color: #e2312e;
  }
}

.page__mainLink {
  position: relative;
  display: block;
  width: 22%;
  min-width: 200px;
  margin: 0 auto;
  padding: 10px;
  background-color: transparent;
  color: #b80b09;
  border: 2px solid #b80b09;
  border-radius: 20px;
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease-out;
  z-index: 5;
  outline: none;

  &:hover {
    color: white
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    right: 50%;
    opacity: 0;
    background-color: #b80b09;
    z-index: -2;
    transition: 0.4s ease-out;
  }

  &:hover::after {
    left: -1px;
    right: -1px;
    opacity: 1;
    border-radius: 17px;
  }
}

.page__logos {
  display: flex;
  justify-content: center;
  margin: 50px auto 0;
  flex-wrap: wrap;
  align-items: center;

  div {
    width: 50%;
    margin-bottom: 50px;
    text-align: center;

    img {
      width: 95%;
    }
  }
}

.scrollToTop {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 50px;
  right: 50px;
  width: 45px;
  height: 45px;
  background-color: rgb(76, 75, 82);
  box-shadow: 2px 3px 6px 0 rgb(100, 100, 100);
  color: #fff;
  font-size: 1.8em;
  z-index: 10;
  cursor: pointer;
}

@media (max-width: 768px) {
  .scrollToTop {
    bottom: 25px;
    right: 25px;
  }
}