.menu__container {
  width: 100%;
  height: 79px;
  background-color: #2b2b2b;
  font-weight: 400;
}

.menu {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.menu__items {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu__item {
  display: block;
  color: rgb(223, 223, 223);
  text-transform: uppercase;
  text-decoration: none;
  padding: 12px;
  cursor: pointer;

  &:hover {
    color: #FFF;
    text-shadow: 0 0 4px #FFF;
  }

  &.active {
    color: #FFF;
    text-shadow: 0 0 4px #FFF;
  }
}

.menu__dropdownitem {
  position: relative;

  &:hover .menu__dropdown {
    display: flex;
    animation-name: dropdown;
    animation-duration: 0.3s;
  }
}

.menu__dropdown {
  position: absolute;
  top: 35px;
  left: -10px;
  right: -150px;
  display: none;
  flex-direction: column;
  background-color: #000000ce;
  padding: 20px 10px;
  z-index: 5;
}

.menu__subitem {
  max-width: 200px;
  padding: 12px 10px;
  color: rgb(223, 223, 223);
  text-decoration: none;
  text-transform: none;
  transition: 0.2s ease-out;
  text-shadow: none !important;

  &:hover {
    color: #FFF;
    text-shadow: 0 0 4px #FFF !important;
  }
}

.menu__logo {
  margin: 10px 20px;
}

.menu__logo img {
  width: 80%;
}

@keyframes dropdown {
  0%   {transform: scale(1);}
  75%  {transform: scale(1.05);}
  100% {transform: scale(1);}
}

.menu__hamburger {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 28px;
  height: 18px;
  cursor: pointer;
  z-index: 5;

  & .bar {
    position: absolute;
    width: 28px;
    height: 3px;
    background-color: #FFF;
    border: 0 solid transparent;
    border-radius: 4px;
    transition: 0.3s ease-in
  }

  & .bar1 {
    top: 0;
  }

  & .bar2 {
    top: 50%;
    transform: translateY(-50%);
  }

  & .bar3 {
    top: 100%;
    transform: translateY(-100%);
  }

  &.active {
    & .bar1 {
      top: 50%;
      transform: rotate(-45deg);
    }
    & .bar2 {
      opacity: 0;
    }
    & .bar3 {
      top: 50%;
      transform: rotate(45deg);
    }
  }
}

@media (max-width: 1024px) {
  .menu__items {
    flex-direction: column;
    justify-content: left;
    position: absolute;
    top: 72px;
    right: 0;
    width: 40%;
    height: calc(100vh - 72px);
    background: #333;
    z-index: 6;
    transform: translateX(100%);
    transition: 0.3s ease-in;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.active {
      transform: translateX(0%);
    }
  }

  .menu__dropdownitem {
    position: static;
    text-align: center;
  
    &:hover .menu__dropdown {
      animation-name: none;
    }
  }
  
  .menu__dropdown {
    position: static;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #000000ce;
    padding: 10px;
    margin-top: 12px;
  }
  
  .menu__subitem {
    color: rgb(223, 223, 223);
    text-decoration: none;
    text-transform: none;
    transition: 0.2s ease-in;
    text-shadow: none !important;
  
    &:hover {
      color: #FFF;
      text-shadow: 0 0 4px #FFF !important;
    }
  }
  
  .menu__hamburger {
    display: block;
  }
}

@media (max-width: 768px) {
  .menu__items {
    width: 60%;
  }
}

.click {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(30, 30, 30, 0.2);
  z-index: 5;
}
