.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 0.9em;
  background-color: #2b2b2b;
  color: rgb(207, 207, 207);
  height: 850px;
}

.footer__container {
  max-width: 1200px;
  width: 95%;
  margin: 30px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__column--first {
  width: 35%;
}

.footer__copyright {
  font-weight: bold;
}

.footer__link {
  text-decoration: none;
  color: #b80b09;
  font-weight: bold;
  transition: 0.2s ease-out;

  &:hover {
    text-decoration: underline;
    color: #e2312e;
  }
}

.footer__info {
  margin-top: 30px;
}

.footer__additional {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.footer__addition--col1, .footer__addition--col2 {
  width: 80%;
}

.footer__addition--col1 span, .footer__addition--col2 span {
  display: block
}

.footer__column--second {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 25%;
}

.footer__menu, .footer__submenu {
  text-decoration: none;
  display: block;
  transition: 0.2s ease-out;

  &:hover {
    color: white;
  }
}

.footer__menu {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  padding-top: 22px;
  color: rgb(207, 207, 207);
  letter-spacing: 1px;
}

.footer__submenu {
  padding-top: 10px;
  color: rgb(207, 207, 207);
  margin-left: 25px;
}

.footer__column--third {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.hours {
  color: #1C77B8;
  font-weight: bold;
  margin-left: 5px;
}

.bottom-info {
  margin-top: 10px;
  display: inline-block;
}

.footer-fb {
  margin-top: 20px;
}

.footer__bottom {
  text-align: center;
  padding: 5px;
}

@media (max-width: 1300px) {
  .footer {
    height: 850px;
  }
}

@media (max-width: 1024px) {
  .footer {
    height: 1300px;
  }

  .footer__column--first {
    width: 60%;
  }

  .footer__column--second {
    width: 40%;
  }

  .footer__column--third {
    width: 100%;
    margin-top: 50px;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .footer {
    height: 1600px;
  }

  .footer__column--first, .footer__column--second {
    width: 100%;
    align-items: center;
    padding: 0 25px;
  }

  .footer__menu, .footer__submenu {
    text-align: center;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .footer {
    height: 1645px;
  }
}
