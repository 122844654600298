.article {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(250, 250, 250);
  box-shadow: 6px 12px 15px 0 rgb(173, 173, 173);
}

.article__top {
  display: flex;
  justify-content: space-between;
}

.article__time {
  display: block;
  font-size: 0.9em;
  color: rgb(102, 102, 102);
}

.article__category {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2em;
  margin: 0;
}

.article__title {
  text-align: center;
  font-size: 1.8em;
}

.article__body {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 35px;
}

.article__bottom {
  display: flex;
  justify-content: flex-end;
}

.article__button {
  display: block;
  text-decoration: none;
  color: #b80b09;
  font-weight: bold;
  transition: 0.2s ease-out;

  &:hover {
    text-decoration: underline;
    color: #e2312e;
  }
}

.article__share {
  width: 38px;
  align-self: flex-end;
}