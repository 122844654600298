html {
  position: relative;
  overflow-x: hidden;
  min-height: calc(100vh - 172px);
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c3410;
  color: rgb(27, 27, 27);

#root {
  position: relative;
  overflow-x: hidden;
  width: 100%;
}

.root {
  margin: 0 0 840px;
}

@media (max-width: 1300px) {
  .root {
    margin: 0 0 850px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin: 0 0 1250px;
  }
}

@media (max-width: 768px) {
  .root {
    margin: 0 0 1570px;
  }
}

@media (max-width: 480px) {
  .root {
    margin: 0 0 1645px;
  }
}
}